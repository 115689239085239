<template>
  <block title="会展分析">
    <div class="rank-row">
      <div class="rank-col" style="width: 60%">
        <div class="changguan-title-1">承办展会<span style="color: #D15900;font-size: 18px;/*font-style: italic;*/">{{totalZh}}</span>场</div>
        <el-timeline reverse>
          <el-timeline-item
              v-for="(activity, index) in zh"
              :key="index"
              :timestamp="activity.jbkssj" hide-timestamp>
            <div class="timeline-content">
              <span style="flex: none;margin-right: 10px;">{{activity.jbkssj}}</span>
              <span style="flex: none;margin-right: 10px;">{{ activity.province }}</span>
              <a class="link" :href="$router.resolve({path:'/zhanhui/details',query:{id:activity.id}}).href" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{ activity.zhmc }}</a>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div class="rank-col-sep"></div>
      <div class="rank-col" style="width: 40%">
        <div class="changguan-title-1" style="text-align: center">行业分析</div>
        <v-chart class="chart" :option="chartOption"/>
      </div>
    </div>
    <div class="rank-row">
      <div class="rank-col" style="width: 100%">
        <div class="changguan-title-1">近一年空置率</div>
        <v-chart class="chart" :option="barOption"/>
      </div>
    </div>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, PieChart} from "echarts/charts";
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart from "vue-echarts";
import moment from "moment";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);
export default {
  name: "ChangguanZhanhuiStatBlock",
  components: {Block, VChart},
  props: {
    changguan: Object
  },
  data() {
    return {
      totalZh:0,
      zh: [],
      hy:[],
      kzl:[]
    }
  },
  created() {
    this.init()
  },
  computed: {
    chartOption() {
      return {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '行业分析',
            type: 'pie',
            radius: ['40%', '70%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
            },
            data: this.hy
          }
        ]
      }
    },
    barOption(){
      return {
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: 'category',
          data: this.kzl.map(i=>i.month),
        },
        yAxis: {
          type: 'value',
          axisLabel:{
            formatter: '{value}%'
          }
        },
        series: [
          {
            data: this.kzl.map(i=>(i.kzd*100/i.td).toFixed(2)),
            type: 'bar',
            barMaxWidth:40,
            label:{
              show:true,
              position:'top',
              formatter: '{c}%'
            }
          }
        ]
      }
    },
  },
  methods: {
    async init() {
      const data=(await api.listZhanhui({
        jbcg: this.changguan.mc,
        state:'END',
        page: 0,
        size: 10,
        sort: 'jbkssj,DESC'
      })).data
      this.totalZh=data.totalElements
      this.zh = data.content.reverse()
      this.hy=(await api.getZhanhuiSshyStat({jbcg: this.changguan.mc})).data.slice(0,10)
      const endDate=moment().endOf("month").format('YYYY-MM-DD')
      const startDate=moment().subtract(11,"month").startOf("month").format('YYYY-MM-DD')
      this.kzl=(await api.listChangguanMonthlyKzl(this.changguan.id,startDate,endDate)).data
    }
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
