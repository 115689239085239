<template>
  <div class="page-content" v-if="changguan">
    <div class="changguan-details-header">
      <div class="changguan-details-header-left">
        <div><img class="changguan-details-header-logo" v-attachment-attr:src="changguan.logo"></div>
      </div>
      <div class="changguan-details-header-right">
        <div class="changguan-details-title">
          {{changguan.mc}}
        </div>
        <el-row>
          <el-col :span="14">
            <div class="changguan-details-fields">
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">展馆地址：</span>
                <span class="info-field-value" :title="changguan.dz">{{changguan.dz}}</span>
              </div>
              <div class="info-field" style="width: 50%">
                <span class="info-field-label">场馆面积：</span>
                <span class="info-field-value">{{changguan.mj}}㎡</span>
              </div>
<!--              <div class="info-field" style="width: 50%">
                <span class="info-field-label">开业时间：</span>
                <span class="info-field-value">{{changguan.kysj}}</span>
              </div>-->
              <div class="info-field" style="width: 100%">
                <img class="info-field-icon" src="../assets/images/tel.png">
                <span class="info-field-value">{{changguan.lxdh}}</span>
              </div>
              <div class="info-field" style="width: 100%">
                <img class="info-field-icon" src="../assets/images/website.png">
                <span class="info-field-value">{{changguan.wz}}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="10" v-if="openingZhanhui&&openingZhanhui.length>0">
            <div class="opening-zhanhui-title">正在举办会展：</div>
            <div>
              <a v-for="item in openingZhanhui" :key="item.id" class="link opening-zhanhui-link"
                 :href="$router.resolve({path:'/zhanhui/details',query:{id:item.id}}).href">{{item.zhmc}}</a>
            </div>
          </el-col>
        </el-row>

      </div>
    </div>
    <el-row :gutter="30">
      <el-col :span="14">
        <block title="场馆介绍">
          <div v-html="richTextToHtml(changguan.jj)" style="height: 360px;overflow: auto;"></div>
        </block>
      </el-col>
      <el-col :span="10">
        <changguan-news-block></changguan-news-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-ddtc-block :changguan="changguan"></changguan-ddtc-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-nearest-hotel-list-block :changguan="changguan"></changguan-nearest-hotel-list-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="布局优势">
          <el-image :src="require('../assets/images/ss/changguan-2.png')" style="width: 100%;height: 124px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="业绩分析">
          <el-image :src="require('../assets/images/ss/changguan-3.png')" style="width: 100%;height: 370px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-zhanhui-list-block :changguan="changguan"></changguan-zhanhui-list-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <changguan-zhanhui-stat-block :changguan="changguan"></changguan-zhanhui-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="竞争对手分析">
          <el-image :src="require('../assets/images/ss/changguan-6.png')" style="width: 100%;height: 479px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import api from "../api";
import HtmlUtils from "../utils/HtmlUtils";
import Block from "./Block";
import ChangguanNewsBlock from "./ChangguanNewsBlock";
import ChangguanDdtcBlock from "./ChangguanDdtcBlock";
import ChangguanNearestHotelListBlock from "./ChangguanNearestHotelListBlock";
import ChangguanZhanhuiListBlock from "./ChangguanZhanhuiListBlock";
import ChangguanZhanhuiStatBlock from "./ChangguanZhanhuiStatBlock";
export default {
  name: "ChangguanDetails",
  components:{
    Block,
    ChangguanNewsBlock,
    ChangguanDdtcBlock,
    ChangguanNearestHotelListBlock,
    ChangguanZhanhuiListBlock,
    ChangguanZhanhuiStatBlock
  },
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      changguan:null,
      openingZhanhui:[]
    }
  },
  created() {
    this.init()
  },
  computed:{
  },
  methods: {
    async init(){
      this.changguan=(await api.getChangguanById(this.$route.query.id)).data
      this.openingZhanhui=(await api.listZhanhui({jbcg:this.changguan.mc,opening:true,page:0,size:10})).data.content.slice(0,4)
    },
    richTextToHtml(str){
      return HtmlUtils.richTextToHtml(str)
    }
  }
}
</script>

<style scoped>

</style>
